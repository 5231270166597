import hexToHsl from 'hex-to-hsl'

class CustomCarousel {
  static async init () {
    const $carousel = $('.owl-custom-carousel')
    $carousel.each(function () {
      const $this = $(this)
      const template = $this.attr('data-template')
      const items = parseInt($this.attr('data-items'), 10)
      const carouselImagesSelector = $this.attr('data-slides-images')
      const options = {
        navContainer: $this.attr('data-nav-container') || '',
        dotsContainer: $this.attr('data-dots-container') || '',
        margin: parseInt($this.attr('data-margin'), 10) === 0 ? 0 : (parseInt($this.attr('data-margin'), 10) || 30),
        dots: !!$this.attr('data-dots-container'),
        nav: !!$this.attr('data-nav-container'),
        loop: $this.attr('data-loop') === 'true',
        autoplay: $this.data('autoplay'),
        navSpeed: parseInt($this.attr('data-nav-speed'), 10) || false,
        dotsSpeed: parseInt($this.attr('data-dots-speed'), 10) || false,
        autoplaySpeed: parseInt($this.attr('data-autoplay-speed'), 10) || false,
        autoplayTimeout: parseInt($this.attr('data-autoplay-timeout')) || 4000,
        animateOut: $carousel.data('animate-out'),
        animateIn: $carousel.data('animate-in'),
        autoplayHoverPause: $this.data('disable-hover-pause'),
        stagePadding: parseInt($this.attr('data-stage-padding')) || 0,
        autoWidth: $this.attr('data-auto-width') === 'true'
      }
      // Events
      if ($this.attr('data-on-initialized')) {
        options.onInitialized = window[$this.attr('data-on-initialized')]
      }
      if ($this.attr('data-on-translate')) {
        options.onTranslate = window[$this.attr('data-on-translate')]
      }
      if ($this.attr('data-on-translated')) {
        options.onTranslated = window[$this.attr('data-on-translated')]
      }
      // Nav text attribute
      if ($this.attr('data-nav-next') && $this.attr('data-nav-prev')) {
        options.navText = [$this.attr('data-nav-next'), $this.attr('data-nav-prev')]
      }
      if (items === 1) {
        options.items = 1
      } else {
        options.responsive = {
          0: {
            items: 1
          },
          768: {
            items: Math.floor(items * 0.5)
          },
          992: {
            items: $this.data('lg-items') ? $this.data('lg-items') : Math.floor(items * 0.75)
          },
          1200: {
            items
          }
        }

        const owlCarouselOptions = window._owlCarouselOptions

        if (owlCarouselOptions) {
          [0, 786, 992, 1200].forEach((width) => {
            if (owlCarouselOptions.responsive && owlCarouselOptions.responsive[width]) {
              options.responsive[width].loop = owlCarouselOptions.responsive[width].loop
            }
          })
        }
      }

      if (carouselImagesSelector) {
        const $carouselImages = $(carouselImagesSelector)
        const carouselImagesOptions = {
          items: 1,
          mouseDrag: false,
          touchDrag: false,
          dots: false,
          nav: false,
          loop: options.loop,
          autoWidth: options.autoWidth
        }
        $carouselImages.owlCarousel(carouselImagesOptions)

        options.onTranslate = function (event) {
          $carouselImages.trigger('to.owl.carousel', event.item.index + 1)
        }
      }

      $this.owlCarousel(options)
      if (template && ['kood', 'ronery'].includes(template)) {
        $this.find(`.${template}-carousel__item`).each(function () {
          const $slide = $(this)
          const bgColor = $slide.attr('data-slide-bg-color').split(' ')[1]
          const lightness = hexToHsl(bgColor)[2]
          if (template === 'kood') {
            const textColor = lightness > 50 ? '#111' : '#fff'
            $('.kood-carousel__item__decor', $slide).css('border-bottom-color', textColor)
            $('.kood-carousel__item__title, .kood-carousel__item__body, .kood-carousel__item__link', $slide).css('color', textColor)
            $slide.find('.kood-carousel__item__gradient').css('background', `radial-gradient(circle at center, ${bgColor}66 0%, ${bgColor} 90%)`)
          } else {
            $slide.addClass(lightness > 50 ? 'light-theme' : 'dark-theme')
            if (template === 'ronery') {
              $slide.find('.ronery-carousel__item__gradient').css('background', `linear-gradient(-90deg, ${bgColor}66 0%, ${bgColor}cc 90%)`)
            }
          }
        })
      }
    })
  }
}

export default CustomCarousel
