import 'owl.carousel'
import ListingsCarousel from 'listings/scripts/carousel'
import SlidesCarousel from 'slides/scripts/carousel'
import CustomCarousel from 'slides/scripts/custom-carousel'

$(function () {
  ListingsCarousel.init()
  SlidesCarousel.init()
  CustomCarousel.init()
})
