class SlidesCarousel {
  static async init () {
    $('.slides-carousel').each((index, element) => {
      const $carousel = $(element)
      const slideBy = parseInt($carousel.data('slide-by')) || 1
      const grids = parseInt($carousel.data('slides-amount'))
      const singleSlide = $carousel.children().length < 2
      const animateOut = $carousel.data('animate-out')
      const animateIn = $carousel.data('animate-in')
      const margin = $carousel.data('margin') || 0
      const doLoop = !!$carousel.data('loop')
      const autoHeight = !!$carousel.data('auto-height')
      const $backgroundCarousel = $carousel.siblings('.slides-carousel-background')
      const autoplayHoverPause = !$carousel.data('disable-hover-pause')
      const autoplayTimeout = parseInt($carousel.data('autoplay-timeout')) || 5000
      const dots = $carousel.data('dots')
      const nav = $carousel.data('nav')

      $carousel.owlCarousel({
        slideBy,
        animateOut,
        animateIn,
        margin,
        autoHeight,
        nav: nav,
        rewind: !singleSlide && doLoop,
        mouseDrag: !singleSlide,
        touchDrag: !singleSlide,
        pullDrag: !singleSlide,
        navText: ['', ''],
        navElement: 'div class="fa"',
        autoplay: true,
        autoplayTimeout: autoplayTimeout,
        autoplayHoverPause: autoplayHoverPause,
        dots: !singleSlide && dots,
        dotsEach: 1,
        responsive: {
          0: {
            items: 1
          },
          576: {
            items: 1
          },
          768: {
            items: 1
          },
          992: {
            items: grids < 3 ? grids : 3
          },
          1200: {
            items: grids
          }
        }
      })

      $backgroundCarousel.owlCarousel({
        nav: false,
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: false,
        items: 1,
        dots: false
      })

      $carousel.on('change.owl.carousel', (event) => {
        if ($backgroundCarousel && event.namespace && event.property.name === 'position') {
          const target = event.relatedTarget.relative(event.property.value, true)
          $backgroundCarousel.owlCarousel('to', target, 300, true)
        }
      })

      $('.owl-carousel').trigger('refresh.owl.carousel')
    })
  }
}

export default SlidesCarousel
